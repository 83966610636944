import { useCookies } from 'react-cookie'

export default function StartTest({ location }) {
  const [, setCookie] = useCookies(['Goodpather'])
  setCookie('Goodpather', 'i_am_a_GOODpather', {
    maxAge: 60 * 60 * 24 * 365 * 10,
    path: '/'
  })
  if (typeof window !== `undefined`) window.location.replace('/')
  return null
}
